import { createTheme } from "@mui/material";
import { purple } from "@mui/material/colors";

const theme = createTheme({
  palette: {
    primary: {
      main: "#660E3A",
    },
    secondary: purple,
  },
  typography: {
    fontFamily: [
      "-apple-system",
      "Roboto",
      "Helvetica",
      "Arial",
      "sans-serif",
      "serif",
    ].join(","),
  },
});

export default theme;
